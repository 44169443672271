@font-face {
font-family: '__batonTurboWeb_f38a8c';
src: url(/_next/static/media/6be8221ed7f5e6a6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: bold;
}

@font-face {
font-family: '__batonTurboWeb_f38a8c';
src: url(/_next/static/media/af72ac228758fc83-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: bold;
}

@font-face {
font-family: '__batonTurboWeb_f38a8c';
src: url(/_next/static/media/7396188b0efe8ba5-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: semi-bold;
}

@font-face {
font-family: '__batonTurboWeb_f38a8c';
src: url(/_next/static/media/f2e6dc23d84db3fd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: semi-bold;
}

@font-face {
font-family: '__batonTurboWeb_f38a8c';
src: url(/_next/static/media/a37334cfc86c7832-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__batonTurboWeb_f38a8c';
src: url(/_next/static/media/c133687eba141dc5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__batonTurboWeb_Fallback_f38a8c';src: local("Arial");ascent-override: 94.98%;descent-override: 24.99%;line-gap-override: 0.00%;size-adjust: 100.03%
}.__className_f38a8c {font-family: '__batonTurboWeb_f38a8c', '__batonTurboWeb_Fallback_f38a8c'
}

